// src/components/Message.js
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";

const Message = ({ msg }) => {
  const primaryColor = useSelector((state) => state.chat.primaryColor);
  const [displayedContent, setDisplayedContent] = useState("");
  const [streamInterval, setStreamInterval] = useState(null);

  useEffect(() => {
    let index = 0;
    const messageLength = msg.content.length;

    // Clear previous interval if there was one
    if (streamInterval) clearInterval(streamInterval);

    const interval = setInterval(() => {
      if (index < messageLength) {
        setDisplayedContent((prev) => prev + msg.content[index]);
        index += 1;
      } else {
        clearInterval(interval);
      }
    }, 50); // Adjust time to control the speed of the "streaming"

    setStreamInterval(interval);

    // Clean up the interval on unmount
    return () => {
      clearInterval(interval);
    };
  }, [msg.content]); // Effect runs when the message content changes
  return (
    <div className="flex flex-col">
      <div className="flex justify-between">
        <p className="text-xs">
          {msg.role === "Assistant" ? "Customer Support" : ""}
        </p>
        <p className="text-xs">{msg.role === "User" ? msg.name : ""}</p>
      </div>
      <div
        className={`flex items-start ${
          // Align items to the top
          msg.role === "Assistant" ? "flex-row" : "flex-row-reverse"
        }`}
      >
        <div
          className={`px-3 py-2 rounded-lg max-w-[70%] ${
            msg.role === "Assistant"
              ? "bg-gray-200 text-left"
              : "text-white bg-blue-800 text-right"
          }`}
          style={{
            backgroundColor: msg.role === "User" ? primaryColor : undefined,
          }}
        >
          {msg.content.split("\n").map((line, i) => (
            <span key={i}>
              {line}
              <br />
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Message;
