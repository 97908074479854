import { useSelector } from "react-redux";

const NavigationControls = ({ onClose, onMinimize }) => {
  const primaryColor = useSelector(
    (state: RootState) => state.chat.primaryColor
  );

  return (
    <div
      className="flex justify-end items-center p-3 rounded-t-lg"
      style={{ backgroundColor: primaryColor }}
    >
      <div className="flex space-x-2">
        <button onClick={onMinimize} className="text-xl font-bold text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          >
            <line x1="6" y1="12" x2="18" y2="12"></line>
          </svg>{" "}
        </button>
        <button onClick={onClose} className="cursor-pointer ml-auto text-white">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="24"
            height="24"
            viewBox="0 0 24 24"
            fill="none"
            stroke="currentColor"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
            className="feather feather-x"
          >
            <line x1="18" y1="6" x2="6" y2="18"></line>
            <line x1="6" y1="6" x2="18" y2="18"></line>
          </svg>
        </button>
      </div>
    </div>
  );
};

export default NavigationControls;
