// src/ChatComponent.js
import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import {
  OuterChatWindow,
  PreChatMessages,
  NavigationControls,
  ChatInterface,
  UserAuthForm,
} from "./components";
import { useDispatch, useSelector } from "react-redux";

import { RootState } from "./state/store.tsx";
import {
  changeChatVisibility,
  changePreMessagesVisibility,
  setCustomerData,
  loadInitialChatSettings,
  initializeWebSocketConnection,
  disconnectWebSocket,
  sendImpressionAnalytics,
  sendClickAnalytics,
} from "./state/chat/chatSlice.tsx";
import axios from "axios";

const Chat = () => {
  const dispatch = useDispatch();
  const chatVisible = useSelector((state: RootState) => state.chat.chatVisible);
  const isUserAuthenticated = useSelector(
    (state: RootState) => state.chat.customer.userAuthenticated
  );

  const [searchParams, setSearchParams] = useSearchParams();
  //http://localhost:3001/?store_id=04f48501-0d49-4ec1-8b74-9a5500701fb4
  const chatbotId =
    searchParams.has("chatbot") && searchParams.get("chatbot").length > 0
      ? searchParams.get("chatbot")
      : "default";

  const [iframePointerEvents, setIframePointerEvents] = useState("none");

  useEffect(() => {
    // Event listener for messages from the iFrame
    const handleFrameMessages = (event) => {
      // Ensure the message is from a trusted source
      if (event.origin !== "The origin of your iFrame content") return;

      if (event.data.pointerEvents === "enable") {
        setIframePointerEvents("auto");
      } else if (event.data.pointerEvents === "disable") {
        setIframePointerEvents("none");
      }
    };

    window.addEventListener("message", handleFrameMessages);

    return () => {
      window.removeEventListener("message", handleFrameMessages);
    };
  }, []);

  useEffect(() => {
    if (chatbotId) {
      // Dispatch the async thunk action
      dispatch(loadInitialChatSettings(chatbotId));
      dispatch(sendImpressionAnalytics(chatbotId));
    }
  }, [chatbotId, dispatch]);

  useEffect(() => {
    if (localStorage.getItem("userName") && localStorage.getItem("userEmail")) {
      dispatch(
        setCustomerData({
          userName: localStorage.getItem("userName"),
          email: localStorage.getItem("userEmail"),
          uuid: localStorage.getItem("userId"),
        })
      );
    }
  }, [dispatch]);

  useEffect(() => {
    if (chatbotId && isUserAuthenticated) {
      const userData = {
        chatbotId,
        userEmail: localStorage.getItem("userEmail"),
        userId: localStorage.getItem("userId"),
        userName: localStorage.getItem("userName"),
      };
      dispatch(initializeWebSocketConnection(userData));
      dispatch(sendClickAnalytics(chatbotId));
    }

    return () => {
      dispatch(disconnectWebSocket());
    };
  }, [chatbotId, isUserAuthenticated, dispatch]);

  const clearPreMessages = () => {
    dispatch(changePreMessagesVisibility(true));
  };

  const iFrameInParentWindow = (width = '25%', height = '25%', background = 'transparant', inset= 'auto 20px 10px auto', position= 'fixed') => {
      let message = { height: height, width: width, background: background, position: position, inset: inset  };
      window.top.postMessage(message, "*");
  };

  const resetChat = () => {
    dispatch(changeChatVisibility(false));
    iFrameInParentWindow();
  };

  const onMinimizeChat = () => {
    dispatch(changeChatVisibility(false));
    iFrameInParentWindow();
  };

  return (
    <div className="chatbot_parent">
      <div className="fixed bottom-0 right-0 mb-4  chatbot_child  sm:w-[500px] ">
        {" "}
        {chatVisible ? (
          isUserAuthenticated ? (
            <OuterChatWindow>
              <NavigationControls
                onClose={resetChat}
                onMinimize={onMinimizeChat}
              />
              <ChatInterface />
            </OuterChatWindow>
          ) : (
            <OuterChatWindow>
              <NavigationControls isBlue onClose={resetChat} />
              <UserAuthForm chatbotId={chatbotId} />{" "}
            </OuterChatWindow>
          )
        ) : (
          <PreChatMessages />
        )}
      </div>
    </div>
  );
};
export default Chat;
