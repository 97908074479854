// src/components/UserAuthForm.js
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { v4 as uuidv4 } from "uuid"; // Add this at the top of your file

import {
  setCustomerData,
  sendClickAnalytics,
} from "../state/chat/chatSlice.tsx";

const UserAuthForm = ({ chatbotId }) => {
  const dispatch = useDispatch();
  const [userName, setUserName] = useState("");
  const [userEmail, setUserEmail] = useState("");
  const primaryColor = useSelector(
    (state: RootState) => state.chat.primaryColor
  );

  const startChat = () => {
    if (userEmail.trim() === "" || userName.trim() === "") {
      alert("Please fill in all fields.");
      return;
    }
    const userId = uuidv4(); // Generate a new UUID
    localStorage.setItem("userId", userId);
    localStorage.setItem("userName", userName);
    localStorage.setItem("userEmail", userEmail);
    localStorage.setItem("chatbotId", chatbotId);

    dispatch(setCustomerData({ userName, email: userEmail, uuid: userId }));
  };

  return (
    <div
      className="w-full h-[700px] sm:h-auto flex flex-col mr-4"
      style={{ backgroundColor: primaryColor }}
    >
      <div
        className="flex flex-col justify-between"
        style={{ backgroundColor: primaryColor }}
      >
        <div className="flex items-center p-3">
          {/* SVG cross icon as close button can be added here */}
        </div>
        <div className="p-6 space-y-4 overflow-y-auto h-[600px] flex-grow flex flex-col justify-center text-white">
          <h1 className="text-3xl font-bold mb-4">How can we help you?</h1>
          <p className="mb-4 text-lg">
            We are always online and available to help.
          </p>
          <input
            type="text"
            value={userName}
            onChange={(e) => setUserName(e.target.value)}
            placeholder="Enter your Full Name"
            className="mb-3 px-4 py-2 w-full border rounded-lg focus:outline-none focus:border-blue-500 text-black"
            required
          />
          <input
            type="email"
            value={userEmail}
            onChange={(e) => setUserEmail(e.target.value)}
            placeholder="Email Address *"
            className="mb-3 px-4 py-2 w-full border rounded-lg focus:outline-none focus:border-blue-500 text-black"
            required
          />
          <button
            className="text-white px-6 py-2 rounded-md shadow-lg"
            style={{ backgroundColor: "#000000" }}
            onClick={startChat}
            disabled={userEmail.trim() === ""}
          >
            Start Chatting
          </button>
        </div>
      </div>
    </div>
  );
};

export default UserAuthForm;
