import React, { useEffect, useRef } from "react";
import Message from "./Message";
import { useSelector } from "react-redux";

const MessageList = () => {
  const chatMessages = useSelector((state) => state.chat.messages);
  const isServerTyping = useSelector((state) => state.chat.isServerTyping);
  const endOfMessagesRef = useRef(null);

  const scrollToBottom = () => {
    endOfMessagesRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    scrollToBottom();
  }, [chatMessages, isServerTyping]); // Effect will run when chatMessages or isServerTyping changes

  return (
    <div className="p-3 space-y-4 overflow-y-scroll set_height scrollbar-hide h-[500px] flex-grow">
      {chatMessages.map((msg, index) => (
        <Message key={index} msg={msg} />
      ))}

      {isServerTyping && (
        <div className="flex justify-start items-center mt-3">
          <span className="typing-dots text-gray-500">typing</span>
        </div>
      )}

      {/* Invisible element for scrolling into view */}
      <div ref={endOfMessagesRef} />
    </div>
  );
};

export default MessageList;
