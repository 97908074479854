const Footer = () => (
  <div className="text-center bg-gray-100 p-2 rounded-b-lg">
    Powered by{" "}
    <a href="https://ecomassist.ai" target="_blank" rel="noreferrer">
      EcomAssist.ai
    </a>
  </div>
);

export default Footer;
