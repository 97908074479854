import React from "react";
import { BrowserRouter } from 'react-router-dom';

import "./App.css";
import Chat from "./ChatComponent";

function App() {
  return (
    <BrowserRouter>
        <div className="App">
            <Chat />
        </div>
    </BrowserRouter>
  );
}

export default App;
