import React, { useEffect } from "react";
import { RiCloseFill } from "react-icons/ri";
import { useDispatch, useSelector } from "react-redux";

import {
  changeChatVisibility,
  setUserInput,
  changePreMessagesVisibility,
} from "../state/chat/chatSlice.tsx";

const PreChatMessages = () => {
  const dispatch = useDispatch();
  const hidePreMessages = useSelector(
    (state: RootState) => state.chat.hidePreMessages
  );
  const suggestedMessages = useSelector(
    (state: RootState) => state.chat.suggestedMessages
  );
  const popupMessage = useSelector(
    (state: RootState) => state.chat.popupMessage
  );

  const primaryColor = useSelector(
    (state: RootState) => state.chat.primaryColor
  );

  const iFrameInParentWindow = (
    width = "95%",
    height = "90%",
    background = "transparant",
    inset = "10px 20px 10px 10px",
    position = "fixed"
  ) => {
    let message = {
      height: height,
      width: width,
      background: background,
      position: position,
      inset: inset,
    };
    window.top.postMessage(message, "*");
  };

  useEffect(() => {
    if (!hidePreMessages && suggestedMessages.length > 0) {
      iFrameInParentWindow(
        "75%",
        "60%",
        "transparant",
        "auto 20px 10px auto",
        "fixed"
      );
    }

    if (hidePreMessages && suggestedMessages.length > 0) {
      iFrameInParentWindow(
        "25%",
        "25%",
        "transparant",
        "auto 20px 10px auto",
        "fixed"
      );
    }
  }, [suggestedMessages, hidePreMessages]);

  const handleStartClick = (message) => {
    dispatch(changeChatVisibility(true));
    iFrameInParentWindow();
  };
  const handleStartingMessageClick = (message) => {
    dispatch(setUserInput(message)); // Update the userInput in the chat state
    dispatch(changeChatVisibility(true)); // Assuming you also want to open the chat when a message is clicked
    iFrameInParentWindow();
  };

  return (
    <div className="flex flex-col items-end space-y-4 pr-4">
      {!hidePreMessages && (
        <button
          className="bg-white w-12 h-12 rounded-full flex items-center justify-center"
          onClick={() => {
            dispatch(changePreMessagesVisibility(true));
          }}
        >
          <RiCloseFill size={25} color="black" />
        </button>
      )}
      {!hidePreMessages && (
        <div className="p-4 rounded-t-lg mb-2 text-white px-4 py-2 rounded-lg text-bold max-w-xs">
          <p
            style={{
              whiteSpace: "break-spaces",
              backgroundColor: primaryColor,
            }}
            className="inline-block shadow-xl text-white px-4 py-2 rounded-lg text-bold mb-5 max-w-xs"
          >
            {popupMessage}
          </p>
          <ul className="space-y-3 text-right">
            {suggestedMessages?.map((msg, index) => (
              <li key={index} className="">
                <button
                  className="text-left bg-gray-200 p-2 rounded-2xl px-4 text-blue-600"
                  onClick={() => handleStartingMessageClick(msg)}
                >
                  {msg}
                </button>
              </li>
            ))}
          </ul>
        </div>
      )}
      <button
        className="w-16 h-16 rounded-full shadow-lg flex items-center justify-center"
        style={{ backgroundColor: primaryColor }}
        onClick={() => handleStartClick()}
      >
        <img
          src="https://cdn.ecomassist.ai/ecomassist-assets/ChatIcon_white.png"
          alt="EcomAssist.ai logo"
          height={50}
          width={50}
        />
      </button>
    </div>
  );
};

export default PreChatMessages;
