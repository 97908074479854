import { ChatInput, MessageList } from "./";
import { useSelector } from "react-redux";

const ChatInterface = ({}) => {
  const primaryColor = useSelector(
    (state: RootState) => state.chat.primaryColor
  );
  return (
    <div
      className="w-full h-full sm:h-auto flex flex-col mr-4 rounded-lg"
      style={{ backgroundColor: primaryColor }}
    >
      <div className="bg-white shadow-lg hello flex flex-col justify-between h-full sm:h-auto">
        <div className="p-3 space-y-4 h-full sm:h-auto flex-grow">
          <MessageList />
        </div>
        <ChatInput />
      </div>
    </div>
  );
};
export default ChatInterface;
