import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { RootState } from "../store.tsx";
import axios from "axios";
import { v4 as uuidv4 } from "uuid";

interface Message {
  name: string;
  role: string;
  timestamp: string;
  content: string;
}

interface Customer {
  userName: string;
  email: string;
  uuid: string;
  userAuthenticated: boolean;
}

interface ChatState {
  messages: Message[];
  chatVisible: boolean;
  hidePreMessages: boolean;
  customer: Customer;
  isServerTyping: boolean;
  socket: WebSocket | null;
  popupMessage: string;
  suggestedMessages: string[];
  primaryColor: string;
  userInput: string;
}

const initialState: ChatState = {
  messages: [],
  chatVisible: false,
  hidePreMessages: JSON.parse(
    localStorage.getItem("hidePreMessages") || "false"
  ),
  customer: {
    userName: "",
    email: "",
    uuid: "",
    userAuthenticated: false,
  },
  isServerTyping: false,
  socket: null,
  popupMessage: "",
  suggestedMessages: [],
  primaryColor: "#0000FF",
  userInput: "",
};

const chatSlice = createSlice({
  name: "chat",
  initialState,
  reducers: {
    changeChatVisibility: (state, action: PayloadAction<boolean>) => {
      state.chatVisible = action.payload;
    },
    changePreMessagesVisibility: (state, action: PayloadAction<boolean>) => {
      state.hidePreMessages = action.payload;
      localStorage.setItem("hidePreMessages", JSON.stringify(action.payload));
    },
    setCustomerData: (state, action: PayloadAction<Customer>) => {
      state.customer.userName = action.payload.userName;
      state.customer.email = action.payload.email;
      state.customer.uuid = action.payload.uuid;
      state.customer.userAuthenticated = true;
    },
    setServerTyping: (state, action: PayloadAction<boolean>) => {
      state.isServerTyping = action.payload;
    },
    receiveMessage: (state, action: PayloadAction<Message>) => {
      const lastMessage = state.messages[state.messages.length - 1];
      if (lastMessage && lastMessage.role === action.payload.role) {
        // Append new content if it's a continuation and not complete
        lastMessage.content += action.payload.content;
      } else {
        // Add a new message
        state.messages.push(action.payload);
      }
      state.isServerTyping = false;
    },
    setWebSocket: (state, action: PayloadAction<WebSocket | null>) => {
      state.socket = action.payload;
    },
    setPopupMessage: (state, action: PayloadAction<string>) => {
      state.popupMessage = action.payload;
    },
    setSuggestedMessages: (state, action: PayloadAction<string[]>) => {
      state.suggestedMessages = action.payload;
    },
    setPrimaryColor: (state, action: PayloadAction<string>) => {
      state.primaryColor = action.payload;
    },
    addMessage: (state, action: PayloadAction<Message>) => {
      state.messages.push(action.payload);
    },
    setUserInput: (state, action: PayloadAction<string>) => {
      state.userInput = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // ... other extraReducers
      .addCase(initializeWebSocketConnection.fulfilled, (state, action) => {
        // WebSocket connection established
        state.socket = action.payload;
      })
      .addCase(disconnectWebSocket.fulfilled, (state) => {
        // WebSocket connection closed
        state.socket = null;
      })
      .addCase(loadInitialChatSettings.fulfilled, (state, action) => {
        state.popupMessage = action.payload.popupMessage;
        state.suggestedMessages = action.payload.suggestedMessages;
        state.primaryColor = action.payload.primaryColor;
      });
  },
});

// Async thunk for loading the initial chat settings
export const loadInitialChatSettings = createAsyncThunk(
  "chat/loadInitialChatSettings",
  async (chatId: string, thunkAPI) => {
    // Replace 'CHATID' with the actual chatId in the URL
    const url = `https://cdn.ecomassist.ai/chatbots/${chatId}.json`;
    const response = await axios.get(url);
    const data = response.data; // Directly access the data without 'default' when using axios.

    // Make sure to match these property names with those from the S3 output structure
    return {
      popupMessage: data.setting.initialMessage, // Assuming you meant 'initialMessage' here as popupMessage
      suggestedMessages: data.setting.suggestedMessage, // Property name should match the S3 output ('suggestedMessage' not 'suggestedMessages')
      primaryColor: data.setting.userMessageColor, // 'primaryColor' matches 'userMessageColor' from the JSON output
    };
  }
);

export const sendMessage = createAsyncThunk(
  "chat/sendMessage",
  async (content: string, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const ws = state.chat.socket;

    if (ws) {
      ws.send(JSON.stringify({ type: "send_message", message: content }));

      // Add the user's message to the state
      const newMessage: Message = {
        name: state.chat.customer.userName || "User",
        role: "User",
        timestamp: new Date().toISOString(),
        content: content,
      };
      thunkAPI.dispatch(setServerTyping(true));

      thunkAPI.dispatch(addMessage(newMessage));
    }
  }
);

export const sendClickAnalytics = createAsyncThunk(
  "chat/sendAnalytics",
  async (chatId: string, thunkAPI) => {
    if (!localStorage.getItem("clickAnalyticsSent")) {
      // Replace 'CHATID' with the actual chatId in the URL
      localStorage.setItem("clickAnalyticsSent", "true");
      const url = `https://api.ecomassist.ai/api/chatbot/${chatId}/click/`;
      const response = await axios.post(url);
      // Make sure to match these property names with those from the S3 output structure
    }
    return {};
  }
);

export const sendImpressionAnalytics = createAsyncThunk(
  "chat/sendAnalytics",
  async (chatId: string, thunkAPI) => {
    // Replace 'CHATID' with the actual chatId in the URL
    if (!localStorage.getItem("clickImpressionSent")) {
      const url = `https://api.ecomassist.ai/api/chatbot/${chatId}/impression/`;
      const response = await axios.post(url);
      localStorage.setItem("clickImpressionSent", "true");
    }
    // Make sure to match these property names with those from the S3 output structure
    return {};
  }
);

export const disconnectWebSocket = createAsyncThunk(
  "chat/disconnectWebSocket",
  (_, thunkAPI) => {
    const state = thunkAPI.getState() as RootState;
    const ws = state.chat.socket;

    if (ws) {
      ws.close();
      thunkAPI.dispatch(setWebSocket(null));
    }
  }
);

// WebSocket Thunk Actions
export const initializeWebSocketConnection = createAsyncThunk(
  "chat/initializeWebSocketConnection",
  async (
    params: {
      chatbotId: string;
      userEmail: string;
      userId: string;
      userName: string;
    },
    thunkAPI
  ) => {
    const { chatbotId, userEmail, userId, userName } = params;

    const getuserIp = async () => {
      try {
        const res = await axios.get("https://api.ipify.org/?format=json");
        return res.data.ip;
      } catch (error) {
        console.error("Error fetching IP address:", error);
        return null;
      }
    };

    if (localStorage.getItem("chatbotId") !== chatbotId) {
      localStorage.setItem("chatbotId", chatbotId);
      const userId = uuidv4();
      localStorage.setItem("userId", userId);
    }

    const ws = new WebSocket(
      `wss://api.ecomassist.ai/ws/chat/${chatbotId}/${userId}/`
    );

    ws.onopen = () => {
      console.log("WebSocket Connected");
      const ip = getuserIp();
      const userAgent = navigator.userAgent;
      const browserLanguage = navigator.language;
      const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      // Send user info as a message
      ws.send(
        JSON.stringify({
          action: "user_info",
          userEmail,
          userId,
          userName,
          ip, // IP address
          userAgent, // User Agent
          browserLanguage, // Browser Language
          timezone, // User Timezone
        })
      );
    };

    ws.onmessage = (event) => {
      const data = JSON.parse(event.data);

      const newMessage: Message = {
        name: "AI",
        role: "Assistant",
        timestamp: new Date().toISOString(),
        content: data.message,
      };
      thunkAPI.dispatch(setServerTyping(false));

      thunkAPI.dispatch(receiveMessage(newMessage));
    };

    thunkAPI.dispatch(setWebSocket(ws));
    return ws; // Return the WebSocket object
  },
  {
    condition: (_, { getState }) => {
      const { chat } = getState();
      return !chat.socket; // Only run if we don't already have a WebSocket connection
    },
  }
);

// export const { increment, decrement, incrementByAmount } = chatSlice.actions;
export const {
  changeChatVisibility,
  changePreMessagesVisibility,
  setCustomerData,
  setServerTyping,
  receiveMessage,
  setWebSocket,
  setPopupMessage,
  setSuggestedMessages,
  addMessage,
  setUserInput,
} = chatSlice.actions;

export default chatSlice.reducer;
