import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { sendMessage, setUserInput } from "../state/chat/chatSlice.tsx"; // Adjust the import path to where your chatSlice is located

const ChatInput = () => {
  const dispatch = useDispatch();
  const primaryColor = useSelector(
    (state: RootState) => state.chat.primaryColor
  );

  const userInput = useSelector((state) => state.chat.userInput);

  const handleSendMessage = async (message) => {
    if (message.trim() === "") return;

    try {
      // Dispatch the sendMessage action and wait for it to complete
      dispatch(sendMessage(message));
      dispatch(setUserInput("")); // Clear the input after sending the message
    } catch (error) {
      // Handle the message send error if needed
      console.error("Failed to send message:", error);
    }
  };

  return (
    <div className="flex justify-start items-center p-3 bg-white border-t-2">
      <input
        value={userInput}
        onChange={(e) => dispatch(setUserInput(e.target.value))} // Dispatch an action to update userInput in the state
        onKeyDown={(e) => {
          if (e.key === "Enter") {
            handleSendMessage(userInput);
          }
        }}
        className="flex-grow mr-2 px-3 py-2 border rounded-md"
        placeholder="Type message"
        type="text"
      />
      <button
        className="text-white px-4 py-2 rounded-md"
        style={{ backgroundColor: primaryColor }}
        onClick={() => handleSendMessage(userInput)}
      >
        Send
      </button>
    </div>
  );
};

export default ChatInput;
