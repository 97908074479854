import React from "react";
import Footer from "./Footer";
import { useSelector } from "react-redux";

const OuterChatWindow = ({ children }) => {
  const primaryColor = useSelector(
    (state: RootState) => state.chat.primaryColor
  );

  return (
    <div
      className="w-full h-[700px] child_chatbot_fix_height sm:h-auto flex flex-col mr-4 mb-4 rounded-lg shadow-xl"
      style={{ backgroundColor: primaryColor }}
    >
      {children}
      <Footer />
    </div>
  );
};

export default OuterChatWindow;
